<template>
  <b-modal
    class="onboarding-modal"
    id="onboardingModal"
    ref="onboardingModal"
    hide-footer
    hide-header
    size="lg"
  >
    <b-colxx xxs="12" class="pl-0 pr-0">
      <glide-component :settings="glideBasicOption" @glideClicked="onGlideClick">
        <div class="glide__slide container">
          <div :key="`home-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary">
                {{ $t('onboarding.page1.title', { appTitle: appTitle }) }}
              </h1>
            </div>
            <div class="d-flex">
              <b-avatar
                src="/assets/img/svg/great-idea.svg"
                size="150"
                class="mx-auto my-4"
                variant="light"
              ></b-avatar>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="$t('onboarding.page1.content', { appTitle: appTitle })"
            ></div>
          </div>
        </div>
        <div class="glide__slide container" v-if="currentUser.role === 'member'">
          <div :key="`member-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary">
                {{ $t('onboarding.page2.team-member.title') }}
              </h1>
            </div>
            <div class="d-flex">
              <b-avatar
                src="/assets/img/svg/team-work.svg"
                size="150"
                class="mx-auto my-4"
                variant="light"
              ></b-avatar>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="$t('onboarding.page2.team-member.content')"
            ></div>
          </div>
        </div>
        <div class="glide__slide container" v-if="currentUser.role === 'leader'">
          <div :key="`leader-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary">
                {{ $t('onboarding.page2.team-leader.title') }}
              </h1>
            </div>
            <div class="d-flex">
              <b-avatar
                src="/assets/img/svg/team-work.svg"
                size="150"
                class="mx-auto my-4"
                variant="light"
              ></b-avatar>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="$t('onboarding.page2.team-leader.content', { appTitle: appTitle })"
            ></div>
          </div>
        </div>
        <div class="glide__slide container">
          <div :key="`final-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary align-self-center m-0 p-0">
                {{ $t('onboarding.page3.title', { team: currentUser.team }) }}
              </h1>
              <span
                v-if="currentUser"
                class="avatar ml-2 align-self-center"
                :style="{ backgroundImage: 'url(' + currentUser.teamImg + ')' }"
              ></span>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="
                $t('onboarding.page3.content', {
                  team: currentUser.team,
                  sitename: $t('general.sitename')
                })
              "
            ></div>
            <div class="d-flex list-item-heading mx-5">
              <b-form-checkbox
                id="checkbox-1"
                class="checkbox align-self-center"
                v-model="status"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                {{ $t('onboarding.button1') }}
              </b-form-checkbox>
              <b-button
                class="align-self-center ml-auto btn-shadow"
                variant="primary"
                size="sm"
                @click="hideModal"
              >
                {{ $t('onboarding.button2') }}
              </b-button>
            </div>
          </div>
        </div>
      </glide-component>
    </b-colxx>
  </b-modal>
</template>

<script>
import GlideComponent from '@/components/Carousel/GlideComponent'
import ContentTile from '@/components/Common/ContentTile'
import onboardingContents from '@/data/onboardingContents'
import { mapGetters } from 'vuex'

export default {
  components: {
    'glide-component': GlideComponent,
    'content-tile': ContentTile
  },
  data() {
    return {
      show: false,
      glideBasicOption: {
        gap: 4,
        perView: 1,
        type: 'slider',
        rewind: false,
        bound: true,
        hideBullets: true
      },
      onboardingContents,
      appTitle: config.VUE_APP_CLIENT || process.env.VUE_APP_CLIENT,
      status: '0',
      glideMoved: 0
    }
  },
  methods: {
    hideModal(refname) {
      // update onboard if checked
      if (this.status === '1') {
        this.$store.dispatch('updateUserOnboarding')
      }
      this.$refs[refname].hide()

      if (refname === 'modalnestedinline') {
        this.$refs['modalnested'].show()
      }
    },
    somethingModal(refname) {
      this.$refs[refname].hide()
      console.log('something modal:: ' + refname)

      if (refname === 'modalnestedinline') {
        this.$refs['modalnested'].show()
      }
    },
    showModal() {
      this.$refs['onboardingModal'].show()
    },
    hideModal() {
      if (this.status === '1') {
        this.$store.dispatch('updateUserOnboarding')
      }
      this.$refs['onboardingModal'].hide()
    },
    onGlideClick() {
      this.glideMoved += 1
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  mounted() {
    /* popover manuel close */
    this.$root.$on('bv::popover::show', () => {
      this.$root.$emit('bv::hide::popover')
    })
    document.addEventListener('click', (e) => {
      if (e.target.id.indexOf('pop') === -1) {
        this.$root.$emit('bv::hide::popover')
      }
    })
    if (this.$router.currentRoute.path !== '/admin/game-control') {
      this.showModal()
    }
  }
}
</script>
