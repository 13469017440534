<template>
  <nav class="navbar fixed-top">
    <div class="d-flex align-items-center navbar-left">
      <logo class="logo align-self-center mx-2" />
      <h6 class="m-0 align-self-center">
        {{ $t('general.sitename') }}
      </h6>
    </div>
    <div class="ml-auto d-flex">
      <div class="menu d-flex align-self-center">
        <div
          class="align-self-center icon-menu-item d-flex mx-3"
          v-for="item in menuItems"
          :key="`item-${item.id}`"
        >
          <router-link :to="item.to" class="mb-0 align-self-center">
            {{ item.label }}
          </router-link>
        </div>
        <div class="align-self-center d-flex language-switcher ml-3">
          <language-selection
            class="mb-0 align-self-center justify-content-end"
            v-model="selectedLanguage"
            @changed="onLanguageChange"
          ></language-selection>
        </div>
      </div>
      <div class="user align-self-center ml-3">
        <b-dropdown
          class="dropdown-menu-right"
          right
          variant="empty"
          toggle-class="p-0"
          menu-class="mt-1 btn-shadow"
          no-caret
        >
          <template slot="button-content">
            <b-avatar
              class="no-border"
              size="2.5em"
              :src="currentUser && currentUser.img ? currentUser.img : ''"
              :text="currentUser && currentUser.title ? getNameInitials(currentUser.title) : ''"
            ></b-avatar>
          </template>
          <b-dropdown-item @click="onAccountClick">
            {{ $t('menu.profile.account') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="currentUser && hasManagementRole" to="/admin">
            {{ $t('menu.profile.management') }}
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="logout">
            {{ $t('menu.profile.signout') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import { languageMixin } from '@/mixins/languageMixin'
import { topMenuItems } from '@/constants/menu'
import Logo from '@/components/Svg/Logo.vue'
export default {
  components: {
    Logo
  },
  data() {
    return {
      appTitle: config.VUE_APP_TITLE || process.env.VUE_APP_TITLE,
      menuItems: topMenuItems,
      selectedLanguage: null
    }
  },
  mixins: [helperMixin, languageMixin],
  methods: {
    ...mapActions(['setLanguage']),
    async logout() {
      let self = this
      await self.$store.dispatch('logout')
      await self.$store.commit('resetState')
      await self.$router.push('/user/login')
    },
    onAccountClick() {
      this.$emit('accountClicked')
    },
    onLanguageChange(lan) {
      this.setLanguage(lan.id)
    },
    routeTo() {
      this.$router.push('/account')
    },
    routeToManagement() {
      this.$router.push('/admin/game-control')
    }
  },
  computed: {
    ...mapGetters(['currentLanguage'])
  },
  mounted() {
    let self = this
    self.setCurrentLanguage()
  }
}
</script>
<style lang="scss" scoped>
.logo {
  margin: 0 0 0 60px;
  h2 {
    margin-bottom: 0;
    font-weight: 600;
  }
}

.menu {
  .icon-menu-item {
    font-size: 1rem;
    color: #545454;
  }

  .nav {
    width: auto;
  }
}

.a-pwc-logo-grid {
  background-image: url('/assets/img/svg/pwc-logo.svg');
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 3rem;
  height: 2.25rem;
  margin: 0 0 0 60px;
}

.router-link-exact-active {
  color: #145388 !important;
  font-weight: 700;
}

.avatar {
  display: block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 99em;
}
</style>
