var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "52px",
        height: "40px",
        viewBox: "0 0 700 276",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("sim-logo")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            opacity: "0.999356951",
          },
        },
        [
          _c("g", { attrs: { id: "sim-logo" } }, [
            _c("path", {
              attrs: {
                d: "M294.550821,234.596911 C303.199974,234.596911 316.170008,234.596911 333.463386,234.596911 C377.296175,279.319942 445.745875,288.916928 500.185459,257.972169 C554.625042,227.02741 581.404127,163.300108 565.410938,102.752383 C549.417749,42.2046573 494.662478,0.0184682934 432.044214,4.2595093e-06 C370.936039,4.25950929e-06 325.104908,4.25950928e-06 294.550821,4.25950927e-06 M418.500504,214.000011 C404.546761,214.000011 391.966993,205.594479 386.627126,192.702901 C381.28726,179.811323 384.23889,164.972475 394.105677,155.105688 C403.972463,145.238901 418.811312,142.287271 431.70289,147.627138 C444.594468,152.967004 453,165.546772 453,179.500516 C453,198.554061 437.55405,214.000011 418.500504,214.000011 L418.500504,214.000011 Z M473.5,131.000011 C454.446176,131.000011 439,115.553835 439,96.5000114 C439,77.4461875 454.446176,62.0000114 473.5,62.0000114 C492.553824,62.0000114 508,77.4461875 508,96.5000114 C508,115.553835 492.553824,131.000011 473.5,131.000011 Z",
                id: "Shape-Copy-2",
                "fill-opacity": "0.8",
                fill: _vm.fill,
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M276.565452,1.41938521e-06 C245.776723,1.4193852e-06 199.593629,1.41938519e-06 138.016171,1.41938518e-06 C75.390418,-0.00893315394 20.6121195,42.163178 4.59935894,102.713474 C-11.4134016,163.263769 15.3554829,227.005831 69.7970617,257.962229 C124.238641,288.918628 192.698603,279.325291 236.536631,234.596911 C254.290518,234.596911 267.605933,234.596911 276.482877,234.596911 M214,148.363648 C214,154.087326 209.360042,158.727284 203.636364,158.727284 L158.727273,158.727284 L158.727273,203.636375 C158.727273,209.360053 154.087315,214.000011 148.363636,214.000011 L127.636364,214.000011 C121.912685,214.000011 117.272727,209.360053 117.272727,203.636375 L117.272727,158.727284 L72.3636364,158.727284 C66.639958,158.727284 62,154.087326 62,148.363648 L62,127.636375 C62,121.912697 66.639958,117.272739 72.3636364,117.272739 L117.272727,117.272739 L117.272727,72.3636477 C117.272727,66.6399694 121.912685,62.0000114 127.636364,62.0000114 L148.363636,62.0000114 C154.087315,62.0000114 158.727273,66.6399694 158.727273,72.3636477 L158.727273,117.272739 L203.636364,117.272739 C209.360042,117.272739 214,121.912697 214,127.636375 L214,148.363648 Z",
                id: "Shape-Copy",
                "fill-opacity": "0.6",
                fill: _vm.fill,
              },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }