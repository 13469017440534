var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "onboardingModal",
      staticClass: "onboarding-modal",
      attrs: {
        id: "onboardingModal",
        "hide-footer": "",
        "hide-header": "",
        size: "lg",
      },
    },
    [
      _c(
        "b-colxx",
        { staticClass: "pl-0 pr-0", attrs: { xxs: "12" } },
        [
          _c(
            "glide-component",
            {
              attrs: { settings: _vm.glideBasicOption },
              on: { glideClicked: _vm.onGlideClick },
            },
            [
              _c("div", { staticClass: "glide__slide container" }, [
                _c("div", { key: `home-glide-${_vm.glideMoved}` }, [
                  _c(
                    "div",
                    { staticClass: "d-flex my-3 justify-content-center" },
                    [
                      _c("h1", { staticClass: "text-primary" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("onboarding.page1.title", {
                                appTitle: _vm.appTitle,
                              })
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-avatar", {
                        staticClass: "mx-auto my-4",
                        attrs: {
                          src: "/assets/img/svg/great-idea.svg",
                          size: "150",
                          variant: "light",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "list-item-heading mx-5 my-4 animate__animated animate__fadeIn",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("onboarding.page1.content", {
                          appTitle: _vm.appTitle,
                        })
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _vm.currentUser.role === "member"
                ? _c("div", { staticClass: "glide__slide container" }, [
                    _c("div", { key: `member-glide-${_vm.glideMoved}` }, [
                      _c(
                        "div",
                        { staticClass: "d-flex my-3 justify-content-center" },
                        [
                          _c("h1", { staticClass: "text-primary" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("onboarding.page2.team-member.title")
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("b-avatar", {
                            staticClass: "mx-auto my-4",
                            attrs: {
                              src: "/assets/img/svg/team-work.svg",
                              size: "150",
                              variant: "light",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "list-item-heading mx-5 my-4 animate__animated animate__fadeIn",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("onboarding.page2.team-member.content")
                          ),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentUser.role === "leader"
                ? _c("div", { staticClass: "glide__slide container" }, [
                    _c("div", { key: `leader-glide-${_vm.glideMoved}` }, [
                      _c(
                        "div",
                        { staticClass: "d-flex my-3 justify-content-center" },
                        [
                          _c("h1", { staticClass: "text-primary" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("onboarding.page2.team-leader.title")
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("b-avatar", {
                            staticClass: "mx-auto my-4",
                            attrs: {
                              src: "/assets/img/svg/team-work.svg",
                              size: "150",
                              variant: "light",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "list-item-heading mx-5 my-4 animate__animated animate__fadeIn",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("onboarding.page2.team-leader.content", {
                              appTitle: _vm.appTitle,
                            })
                          ),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "glide__slide container" }, [
                _c("div", { key: `final-glide-${_vm.glideMoved}` }, [
                  _c(
                    "div",
                    { staticClass: "d-flex my-3 justify-content-center" },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "text-primary align-self-center m-0 p-0",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("onboarding.page3.title", {
                                  team: _vm.currentUser.team,
                                })
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.currentUser
                        ? _c("span", {
                            staticClass: "avatar ml-2 align-self-center",
                            style: {
                              backgroundImage:
                                "url(" + _vm.currentUser.teamImg + ")",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "list-item-heading mx-5 my-4 animate__animated animate__fadeIn",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("onboarding.page3.content", {
                          team: _vm.currentUser.team,
                          sitename: _vm.$t("general.sitename"),
                        })
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex list-item-heading mx-5" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "checkbox align-self-center",
                          attrs: {
                            id: "checkbox-1",
                            name: "checkbox-1",
                            value: "1",
                            "unchecked-value": "0",
                          },
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("onboarding.button1")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "align-self-center ml-auto btn-shadow",
                          attrs: { variant: "primary", size: "sm" },
                          on: { click: _vm.hideModal },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("onboarding.button2")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }