<template>
  <div id="app-container">
    <top-nav @accountClicked="onAccountClick" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <onboarding-modal v-if="currentUser && currentUser.onboarding" />
    <footer-component />

    <b-modal
      id="account-modal"
      ref="account-modal"
      v-if="currentUser"
      hide-header
      hide-footer
      body-class="text-center account-modal-body"
      size="sm"
    >
      <span class="role-badge">
        <b-badge variant="warning" pill>
          {{ currentUser.role }}
        </b-badge>
      </span>
      <b-avatar
        :src="currentUser.img ? currentUser.img : ''"
        size="5em"
        :text="currentUser.title ? getNameInitials(currentUser.title) : ''"
        class="d-block m-auto no-border"
      >
      </b-avatar>
      <h2 class="mt-3">{{ currentUser.title }}</h2>
      <h6 class="mb-0 text-uppercase">
        {{ currentUser.team }}
      </h6>
    </b-modal>
    <notification-container></notification-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopNav from '@/containers/TopNav'
import Footer from '@/containers/Footer'
import OnboardingModal from '@/components/Common/OnboardingModal'
import { helperMixin } from '@/mixins/helperMixin'
import NotificationContainer from '@/components/Notification/NotificationContainer.vue'
export default {
  components: {
    'top-nav': TopNav,
    'footer-component': Footer,
    'onboarding-modal': OnboardingModal,
    NotificationContainer
  },
  mixins: [helperMixin],
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    ...mapActions(['fetchAppConfiguration']),
    onAccountClick() {
      this.$refs['account-modal'].show()
    }
  },
  async created() {
    await this.fetchAppConfiguration()
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.account-modal-body {
  .role-badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-family: 'Nunito Bold';
  }

  h2 {
    font-family: 'Nunito Bold';
  }
}
</style>
