var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app-container" } },
    [
      _c("top-nav", { on: { accountClicked: _vm.onAccountClick } }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _vm.currentUser && _vm.currentUser.onboarding
        ? _c("onboarding-modal")
        : _vm._e(),
      _vm._v(" "),
      _c("footer-component"),
      _vm._v(" "),
      _vm.currentUser
        ? _c(
            "b-modal",
            {
              ref: "account-modal",
              attrs: {
                id: "account-modal",
                "hide-header": "",
                "hide-footer": "",
                "body-class": "text-center account-modal-body",
                size: "sm",
              },
            },
            [
              _c(
                "span",
                { staticClass: "role-badge" },
                [
                  _c("b-badge", { attrs: { variant: "warning", pill: "" } }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.currentUser.role) + "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-avatar", {
                staticClass: "d-block m-auto no-border",
                attrs: {
                  src: _vm.currentUser.img ? _vm.currentUser.img : "",
                  size: "5em",
                  text: _vm.currentUser.title
                    ? _vm.getNameInitials(_vm.currentUser.title)
                    : "",
                },
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.currentUser.title)),
              ]),
              _vm._v(" "),
              _c("h6", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v("\n      " + _vm._s(_vm.currentUser.team) + "\n    "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("notification-container"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }