var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container content-tile" }, [
    _vm.data.thumb
      ? _c(
          "div",
          { staticClass: "d-flex flex-row mb-5 row justify-content-center" },
          [
            _c(
              "div",
              {
                class: [
                  _vm.data.size === "lg" ? "col-lg-5" : "col-lg-3",
                  "col-md-12 col-sm-12",
                ],
              },
              [
                _vm.data.link
                  ? _c(
                      "router-link",
                      {
                        staticClass: "d-block position-relative",
                        attrs: { to: _vm.data.link },
                      },
                      [
                        _c("img", {
                          staticClass: "border-0",
                          attrs: { src: _vm.data.thumb, alt: "thumbnail" },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.data.link
                  ? _c("img", {
                      staticClass: "border-0",
                      attrs: { src: _vm.data.thumb, alt: "thumbnail" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  _vm.data.size === "lg" ? "col-lg-7" : "col-lg-9",
                  "col-md-12 col-sm-12",
                ],
              },
              [
                _vm.data.link
                  ? _c(
                      "router-link",
                      {
                        staticClass: "d-block position-relative",
                        attrs: { to: _vm.data.link },
                      },
                      [
                        _c("h1", { staticClass: "nav-link active mb-1" }, [
                          _vm._v(_vm._s(_vm.data.title)),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.data.link
                  ? _c("h1", { staticClass: "nav-link active mb-1" }, [
                      _vm._v(_vm._s(_vm.data.title)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", {
                  class: [
                    _vm.data.size === "lg"
                      ? "list-item-heading-lg"
                      : "list-item-heading",
                  ],
                  domProps: { innerHTML: _vm._s(_vm.data.subtitle) },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.data.thumb
      ? _c(
          "div",
          { staticClass: "d-flex flex-row mb-5 row justify-content-center" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm.data.link
                  ? _c(
                      "router-link",
                      {
                        staticClass: "d-block position-relative",
                        attrs: { to: _vm.data.link },
                      },
                      [
                        _c("h1", { staticClass: "nav-link active mb-1" }, [
                          _vm._v(_vm._s(_vm.data.title)),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.data.link
                  ? _c("h1", { staticClass: "nav-link active mb-1" }, [
                      _vm._v(_vm._s(_vm.data.title)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", {
                  class: [
                    _vm.data.size === "lg"
                      ? "list-item-heading-lg"
                      : "list-item-heading",
                  ],
                  domProps: { innerHTML: _vm._s(_vm.data.subtitle) },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }