var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "navbar fixed-top" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center navbar-left" },
      [
        _c("logo", { staticClass: "logo align-self-center mx-2" }),
        _vm._v(" "),
        _c("h6", { staticClass: "m-0 align-self-center" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("general.sitename")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "ml-auto d-flex" }, [
      _c(
        "div",
        { staticClass: "menu d-flex align-self-center" },
        [
          _vm._l(_vm.menuItems, function (item) {
            return _c(
              "div",
              {
                key: `item-${item.id}`,
                staticClass: "align-self-center icon-menu-item d-flex mx-3",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "mb-0 align-self-center",
                    attrs: { to: item.to },
                  },
                  [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "align-self-center d-flex language-switcher ml-3" },
            [
              _c("language-selection", {
                staticClass: "mb-0 align-self-center justify-content-end",
                on: { changed: _vm.onLanguageChange },
                model: {
                  value: _vm.selectedLanguage,
                  callback: function ($$v) {
                    _vm.selectedLanguage = $$v
                  },
                  expression: "selectedLanguage",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user align-self-center ml-3" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "dropdown-menu-right",
              attrs: {
                right: "",
                variant: "empty",
                "toggle-class": "p-0",
                "menu-class": "mt-1 btn-shadow",
                "no-caret": "",
              },
            },
            [
              _c(
                "template",
                { slot: "button-content" },
                [
                  _c("b-avatar", {
                    staticClass: "no-border",
                    attrs: {
                      size: "2.5em",
                      src:
                        _vm.currentUser && _vm.currentUser.img
                          ? _vm.currentUser.img
                          : "",
                      text:
                        _vm.currentUser && _vm.currentUser.title
                          ? _vm.getNameInitials(_vm.currentUser.title)
                          : "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-dropdown-item", { on: { click: _vm.onAccountClick } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("menu.profile.account")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.currentUser && _vm.hasManagementRole
                ? _c("b-dropdown-item", { attrs: { to: "/admin" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("menu.profile.management")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("b-dropdown-divider"),
              _vm._v(" "),
              _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("menu.profile.signout")) +
                    "\n        "
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }