<template>
  <div class="container content-tile">
    <div class="d-flex flex-row mb-5 row justify-content-center" v-if="data.thumb">
      <div v-bind:class="[data.size === 'lg' ? 'col-lg-5' : 'col-lg-3', 'col-md-12 col-sm-12']">
        <router-link class="d-block position-relative" v-if="data.link" :to="data.link">
          <img :src="data.thumb" alt="thumbnail" class="border-0" />
        </router-link>
        <img v-if="!data.link" :src="data.thumb" alt="thumbnail" class="border-0" />
      </div>
      <div v-bind:class="[data.size === 'lg' ? 'col-lg-7' : 'col-lg-9', 'col-md-12 col-sm-12']">
        <router-link class="d-block position-relative" v-if="data.link" :to="data.link">
          <h1 class="nav-link active mb-1">{{ data.title }}</h1>
        </router-link>
        <h1 v-if="!data.link" class="nav-link active mb-1">{{ data.title }}</h1>
        <p
          v-html="data.subtitle"
          v-bind:class="[data.size === 'lg' ? 'list-item-heading-lg' : 'list-item-heading']"
        ></p>
      </div>
    </div>
    <div class="d-flex flex-row mb-5 row justify-content-center" v-if="!data.thumb">
      <div class="col-12">
        <router-link class="d-block position-relative" v-if="data.link" :to="data.link">
          <h1 class="nav-link active mb-1">{{ data.title }}</h1>
        </router-link>
        <h1 v-if="!data.link" class="nav-link active mb-1">{{ data.title }}</h1>
        <p
          v-html="data.subtitle"
          v-bind:class="[data.size === 'lg' ? 'list-item-heading-lg' : 'list-item-heading']"
        ></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data']
}
</script>
