<template>
  <b-toast
    :id="notification.id"
    :title="notification.title"
    :message="notification.message"
    :variant="setVariant(notification.type)"
    :solid="solid"
    :toaster="toaster"
    :append="append"
    no-close-button
    no-auto-hide
    class="notification-toast"
    v-model="visible"
  >
    {{ notification.message }}
  </b-toast>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    notification: {
      type: Object,
      required: true
    },
    solid: {
      type: Boolean,
      default: false
    },
    toaster: {
      type: String,
      default: 'b-toaster-top-center'
    },
    append: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeout: null,
      visible: false
    }
  },
  mounted() {
    this.display()
  },
  methods: {
    ...mapActions('notification', ['removeNotification']),
    display() {
      let self = this
      self.visible = true
      self.timeout = setTimeout(() => {
        self.visible = false
        self.removeNotification(self.notification)
      }, 3000)
    },
    setVariant(type) {
      if (type === 'error') {
        return 'danger'
      }

      return type
    }
  },
  beforeDestroy() {
    let self = this
    clearTimeout(self.timeout)
    self.timeout = null
  }
}
</script>
<style lang="scss" scoped>
.b-toaster-leave-active {
  width: 100%;
}
</style>
