var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "carouselImages", staticClass: "glide" }, [
      _c(
        "div",
        { staticClass: "glide__track", attrs: { "data-glide-el": "track" } },
        [_c("div", { staticClass: "glide__slides" }, [_vm._t("default")], 2)]
      ),
      _vm._v(" "),
      !_vm.settings.hideNav
        ? _c(
            "div",
            {
              staticClass: "glide__arrows slider-nav",
              attrs: { "data-glide-el": "controls" },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "glide__arrow glide__arrow--left left-arrow btn btn-link",
                  attrs: { disabled: _vm.isStart, "data-glide-dir": "<" },
                  on: { click: _vm.onGlideClick },
                },
                [_c("i", { staticClass: "fa fa-chevron-left" })]
              ),
              _vm._v(" "),
              !_vm.settings.hideBullets
                ? _c(
                    "div",
                    {
                      staticClass: "glide__bullets slider-dot-container",
                      attrs: { "data-glide-el": "controls[nav]" },
                    },
                    _vm._l(_vm.total, function (i) {
                      return _c("button", {
                        key: i,
                        staticClass: "glide__bullet slider-dot",
                        attrs: { "data-glide-dir": `=${i}` },
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "glide__arrow glide__arrow--right right-arrow btn btn-link",
                  attrs: { disabled: _vm.isEnd, "data-glide-dir": ">" },
                  on: { click: _vm.onGlideClick },
                },
                [_c("i", { staticClass: "fa fa-chevron-right" })]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }