<template>
  <div class="notification-container">
    <notification-bar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    ></notification-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NotificationBar from './NotificationBar.vue'
export default {
  components: {
    NotificationBar
  },
  computed: mapState('notification', ['notifications'])
}
</script>
